<template>
  <div>
    <div v-if="list.length > 0 && !loading">
      <div v-for="(order, k) in list" :key="k" flat @click="openOrder(order)">
        <b-card img-alt="Image" img-top class="mb-2">
          <div class="row">
            <div class="col">
              <OrderListGroup :order="order" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <OrderCustomerEdit
                :orderId="order.id"
                :idx="k"
                :name="getCustomerName(order)"
                :document="getCustomerDocument(order)"
                :phone="getCustomerPhone(order)"
                :email="getCustomerEmail(order)"
                @save="setCustomerName"
              />
              <h3 class="mt-5">
                <strong>{{ getCustomerName(order) }}</strong>
              </h3>
              {{ order.laboratory.name }}<br />
              <div v-if="order.category">
                {{ order.category.name }}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center"></div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="text-center" v-if="loading">
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderListGroup from './OrderListGroup'
import OrderCustomerEdit from './OrderCustomerEdit'

export default {
  name: 'OrdersList',

  components: { OrderListGroup, OrderCustomerEdit },

  data: () => ({
    list: []
  }),

  watch: {
    orders(val) {
      if (val) {
        this.list = JSON.parse(JSON.stringify(val))
      }
    }
  },

  computed: {
    ...mapGetters('orders', {
      loading: 'GET_LOADER'
    }),

    orders() {
      return this.$store.state.orders.orders
    }
  },

  mounted() {
    this.fetchOrders(this.$route.query)
  },

  methods: {
    ...mapActions('orders', ['fetchOrders']),

    setCustomerName(customer) {
      this.$nextTick(() => (this.list[customer.idx] = customer.customer))
      window.location.reload()
    },

    getCustomerName(order) {
      let nome = 'SEM NOME'

      if (order.details) {
        if (order.details.orderMembers) {
          if (order.details.orderMembers.length > 0) {
            const customer = order.details.orderMembers.filter(
              (item) => item.orderMemberType.description === 'PACIENTE'
            )
            if (customer.length == 1) {
              nome = customer[0].name
            }
          }
        }
      } else if (order.customer) {
        if (order.customer.name) {
          return order.customer.name
        }
      } else if (order.data) {
        if (order.data.orderMembers) {
          if (order.data.orderMembers.length > 0) {
            const customer = order.data.orderMembers.filter(
              (item) => item.orderMemberType.description === 'PACIENTE'
            )
            if (customer[0]) {
              nome = customer[0].name
            }
          }
        }
      } else {
        nome = 'SEM NOME'
      }

      return nome
    },

    getCustomerDocument(order) {
      if (order.customer) {
        if (order.customer.document) {
          return order.customer.document
        }
      }
      return null
    },

    getCustomerEmail(order) {
      if (order.customer) {
        if (order.customer.email) {
          return order.customer.email
        }
      }
      return null
    },

    getCustomerPhone(order) {
      if (order.customer) {
        if (order.customer.phone) {
          return order.customer.phone
        }
      }
      return null
    },

    async openOrder(order) {
      this.$router.push(`/ordens/${order.id}`)

      return false
    }
  }
}
</script>

<style lang="css">
.disabled {
  opacity: 0.6;
}
.card-body {
  padding: 2rem;
}
</style>
