<template>
  <div class="mt-6" v-if="images.length > 0">
    <h2>Imagem certificado:</h2>
    <q-card>
      <div v-if="loading" class="text-center">
        <b-spinner label="Loading..." variant="primary"></b-spinner>
      </div>
      <q-card-section v-else>
        <b-container fluid>
          <br />
          <b-img
            v-for="(img, idx) in images"
            :key="idx"
            thumbnail
            rounded
            fluid
            :src="img.image"
            :class="imageClass(img.id)"
            @click="setImage(img)"
          ></b-img>
        </b-container>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'ImageGallery',

  props: ['integrated'],

  data: () => ({
    loading: true,
    image: null,
    images: []
  }),

  methods: {
    getImages() {
      this.$http
        .get('/images')
        .then(({ data }) => {
          this.images = data
        })
        .finally(() => (this.loading = false))
    },

    imageClass(id) {
      if (this.integrated) {
        return {
          image: true,
          active: id === this.image
        }
      }
      return {
        image: true,
        active: id === this.image
      }
    },

    setImage(img) {
      this.image = img.id
      this.$emit('input', img.id)
    }
  },

  mounted() {
    this.getImages()
  }
}
</script>

<style lang="scss" scoped>
.image {
  width: 120px;
  margin: 10px;
}
.active {
  background-color: blue;
}
</style>
