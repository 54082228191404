<template>
  <div>
    <div class="row">
      <div class="col text-center">
        <q-chip color="negative" size="lg" dark="">
          {{ order.number }}
        </q-chip>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div class="text-h5 q-mb-md">
          {{ order.customer.name }}
        </div>
      </div>
    </div>
    <div class="row">
      <q-card class="q-pa-sm" style="width: 100%">
        <Prescription :items="data.info" />
      </q-card>
    </div>
    <hr class="q-mb-lg q-mt-lg" />
    <div class="row q-mb-lg">
      <q-card class="q-pa-md" style="width: 100%">
        <q-input
          color="indigo-14"
          v-model="name"
          label="Nome"
          :rules="[(name) => !!name || 'Nome é obrigatórios']"
          :disable="profile.isAdmin || profile.isLab"
        >
          <template v-slot:prepend>
            <q-icon name="face" />
          </template>
        </q-input>
        <q-input
          color="indigo-14"
          v-model="email"
          label="Email"
          :rules="[(email) => !!email || 'Email é obrigatório']"
          :disable="profile.isAdmin || profile.isLab"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>
        <q-input
          color="indigo-14"
          v-model="phone"
          label="Celular, com ddd, ex. 11 987654321"
          :disable="profile.isAdmin || profile.isLab"
          maxlength="11"
          :rules="[(phone) => !!phone || 'Celular é obrigatório']"
        >
          <template v-slot:prepend>
            <q-icon name="phone" />
          </template>
        </q-input>
      </q-card>
    </div>
    <div class="row q-mt-lg" v-if="profile.isStore || profile.isSalesman">
      <div class="col">
        <q-btn
          :loading="loading"
          class="full-width"
          :size="'lg'"
          dense
          color="indigo-14"
          expanded
          unelevated
          :label="'ENVIAR CERTIFICADO'"
          @click="generate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Prescription from '../../components/Prescription'

export default {
  name: 'OrderForm',

  components: { Prescription },

  data() {
    return {
      name: null,
      email: null,
      phone: null,
      loading: false
    }
  },

  computed: {
    order() {
      return this.$store.state.orders.order
    },

    data() {
      return JSON.parse(this.order.data)
    },

    profile() {
      return this.$store.state.auth
    }
  },

  methods: {
    generate() {
      if (!this.name) {
        this.$alert('nome é obrigatório')
        return true
      }
      if (!this.email) {
        this.$alert('email é obrigatório')
        return true
      }
      if (!this.phone) {
        this.$alert('celular é obrigatório')
        return true
      }
      this.loading = true
      this.$axios
        .post('/certificate/generate', {
          id: this.order.id,
          email: this.email,
          phone: this.phone
        })
        .then((res) => {
          this.loading = false
          this.$q.notify({
            progress: true,
            timeout: 2000,
            position: 'top',
            message: 'Certificado gerado com sucesso e enviado para o cliente.',
            color: 'secondary',
            multiLine: true,
            avatar: '/favicon.png'
          })
          this.$store.dispatch('orders/removeFromList', this.order.id)
          // this.$router.push("/dashboard");
          if (this.phone !== '(__) ____-_____') {
            this.$q
              .dialog({
                title:
                  'Enviamos instruções para o email cadastrado. Quer enviar também por WhatsApp?',
                cancel: true
              })
              .onOk(() => {
                const suffix = process.env.DEV ? 'local' : 'app'
                const url = `https://api.opticonecta.${suffix}/show`
                const number = this.phone
                  .replace('(', '')
                  .replace(')', '')
                  .replace(' ', '')
                  .replace('-', '')

                this.$router.push(
                  `/dashboard?number=${number}&text=${encodeURI(
                    `${url}/${res.data.id}`
                  )}`
                )
              })
              .onCancel(() => {
                // console.log('Cancel')
              })
              .onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
          }
        })
    }
  },

  async mounted() {
    const { customer } = await this.$store.state.orders.order
    this.name = customer.name
    this.email = customer.email
    this.phone = customer.mobile
  }
}
</script>
