<template>
  <div>
    <q-card>
      <q-card-section class="self-center q-mt-md">
        <label>Marca</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="MARCA"
          name="text"
          ref="text"
          v-model="form.brand"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        />
      </q-card-section>
    </q-card>
    <q-card>
      <q-card-section class="self-center q-mt-md">
        <label>Produto</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="PRODUTO"
          name="text"
          ref="text"
          v-model="form.product"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        />
      </q-card-section>
    </q-card>

    <q-card>
      <q-card-section class="self-center q-mt-md">
        <label>Pedido</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="PEDIDO"
          name="text"
          ref="text"
          v-model="form.order"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        />
        <label>Outros</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="OUTROS"
          name="text"
          ref="text"
          v-model="form.others"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        />
      </q-card-section>
    </q-card>

    <q-card v-if="hasDiopter">
      <q-card-section class="self-center q-mt-md">
        <h1 style="text-align: right; margin: 0">Olho direito</h1>
        <label>Esférico</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Esférico"
          name="right_espherical"
          v-model="form.right.espherical"
          id="right.espherical"
          readonly="true"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('right.espherical')"
        />
        <label class="mt-2">Cilíndrico</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Cilíndrico"
          name="right_cylindrical"
          v-model="form.right.cylindrical"
          id="right.cylindrical"
          autocomplete="off"
          readonly="true"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('right.cylindrical')"
        />
        <label class="mt-2">Eixo</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Eixo"
          name="right_axis"
          v-model="form.right.axis"
          autocomplete="off"
          readonly="true"
          id="right.axis"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('right.axis')"
        />
        <label class="mt-2">Adição</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Adição"
          name="right_addition"
          v-model="form.right.addition"
          id="right.addition"
          readonly="true"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('right.addition')"
        />
      </q-card-section>
    </q-card>

    <q-card v-if="hasDiopter">
      <q-card-section class="self-center q-mt-md">
        <h1 style="text-align: right; margin: 0">Olho esquerdo</h1>
        <label>Esférico</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="number"
          placeholder="Esférico"
          name="left_espherical"
          v-model="form.left.espherical"
          id="left.espherical"
          autocomplete="off"
          readonly="true"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('left.espherical')"
        />
        <label class="mt-2">Cilíndrico</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Cilíndrico"
          name="left_cylindrical"
          v-model="form.left.cylindrical"
          id="left.cylindrical"
          readonly="true"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('left.cylindrical')"
        />
        <label class="mt-2">Eixo</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="number"
          placeholder="Eixo"
          name="left_axis"
          v-model="form.left.axis"
          id="left.axis"
          autocomplete="off"
          readonly="true"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('left.axis')"
        />
        <label class="mt-2">Adição</label>
        <input
          class="form-control form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Adição"
          name="left_addition"
          v-model="form.left.addition"
          id="left.addition"
          autocomplete="off"
          readonly="true"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @focus="openKeyboard('left.addition')"
        />
      </q-card-section>
    </q-card>

    <OptCalculator
      v-if="isKeyboardOpen"
      :isOpen="isKeyboardOpen"
      :field="field"
      @input-change="setFieldValue"
      @close="closeKeyboard"
      @ok="setFieldValue"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import _ from 'lodash'

export default {
  name: 'ProductManufacturerManual',

  data: () => ({
    field: null,
    isKeyboardOpen: false,
    form: {
      brand: null,
      product: null,
      order: null,
      others: null,
      right: {
        espherical: null,
        cylindrical: null,
        axis: null,
        addition: null
      },
      left: {
        espherical: null,
        cylindrical: null,
        axis: null,
        addition: null
      }
    }
  }),

  watch: {
    form: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('menus', {
      manufactureres: 'getManufacturers',
      panels: 'getPanels'
    }),

    hasDiopter() {
      return !!this.panels.filter((item) => item.has_diopter).length
    },

    ...mapGetters('orders', {
      order: 'getNewOrder'
    }),

    variant() {
      if (this.brand) {
        return 'primary'
      }
      return 'secondary'
    },

    type() {
      return this.order.type_id
    },

    manufacturer() {
      return this.order.manufacturer_id
    }
  },

  methods: {
    ...mapActions('menus', ['addPanel', 'fetchProducts', 'setTechnology']),

    ...mapActions('orders', ['setManufacturer']),

    selectNextTab(el) {
      el.nextSibling.nextSibling.focus()
    },

    openKeyboard(field) {
      this.$nextTick(() => {
        this.field = field
        this.isKeyboardOpen = true
      })
    },

    closeKeyboard() {
      this.field = null
      this.isKeyboardOpen = false
    },

    setFieldValue(item) {
      const items = item.field.split('.')
      if (items.length > 1) {
        this.form[items[0]][items[1]] = item.value
      } else {
        this.form[item.field] = item.value
      }
      if (item.close) {
        this.$nextTick(() => {
          let element = document.getElementById(item.field)

          if (item.field === 'right.espherical') {
            this.openKeyboard('right.cylindrical')
            this.selectNextTab(element)
          }
          if (item.field === 'right.cylindrical') {
            this.openKeyboard('right.axis')
            this.selectNextTab(element)
          }
          if (item.field === 'right.axis') {
            this.openKeyboard('right.addition')
            this.selectNextTab(element)
          }
          if (item.field === 'right.addition') {
            this.openKeyboard('left.espherical')
            element = document.getElementById('left.espherical')
            element.focus()
          }
          if (item.field === 'left.espherical') {
            this.openKeyboard('left.cylindrical')
            this.selectNextTab(element)
          }
          if (item.field === 'left.cylindrical') {
            this.openKeyboard('left.axis')
            this.selectNextTab(element)
          }
          if (item.field === 'left.axis') {
            this.openKeyboard('left.addition')
            this.selectNextTab(element)
          }
          if (item.field === 'left.addition') {
            this.closeKeyboard()
          }

          setTimeout(() => {
            window.scrollTo({
              top: element.offsetTop + window.pageYOffset,
              left: 0,
              behavior: 'smooth'
            })
          }, 250)
          this.closeKeyboard()
        })
      }
    },

    async setValue() {
      let loader = this.$loading.show()
      await this.setManufacturer(1)
      let params = {
        type_id: this.type,
        manufacturer_id: this.manufacturer,
        all: 1
      }

      if (this.$route.query.manual == 1) {
        params = { all: 1 }
      }

      this.$http
        .query('/lists/technologies', {
          params: params
        })
        .then(({ data }) => {
          this.$store.commit('menus/SET_TECHNOLOGY', _.chunk(data, 2))
        })
        .finally(() => loader.hide())
      this.addPanel({
        label: this.brand,
        action: 'removeManufacturer'
      })
    }
  }
}
</script>

<style lang="css">
.form-control-solid:focus {
  background: whitesmoke;
}
</style>
