<template>
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <b-modal
      id="modal-1"
      title="Confirme o seu Cadastro"
      @hide="checkConfirmed"
      @close="checkConfirmed"
      @ok="resendConfirmation"
      @cancel="checkConfirmed"
      cancel-disabled
      cancel-title="cancelar"
      ok-title="REENVIAR EMAIL CONFIRMAÇÃO"
      ok-only
      modal-cancel
    >
      <div>
        <p class="my-4">
          Enviamos um email com instruções para confirmar o seu cadastro. <br />
          Se não receber, verifique seu lixo eletrônico. Caso necessário, entre
          em contato com o suporte.
        </p>
      </div>
    </b-modal>
    <div class="backBottom" v-if="user.has_lab"></div>
    <div
      class="card-header border-0 bg-danger py-5"
      :style="`background-color:${color}!important;border:1px solid ${color}`"
    >
      <div class="card-toolbar text-center w-100 row">
        <div class="col">
          <MonthSwitcher @input="setMonth" />
        </div>
        <div class="col">
          <YearSwitcher @input="setYear" />
        </div>
      </div>
    </div>

    <div class="card-body p-0 position-relative overflow-hidden">
      <div
        class="bg-danger text-center text-white w-100 h94"
        :style="`background-color:${color}!important;border:1px solid ${color}`"
      >
        <h3>Certificados Personalizados</h3>
      </div>

      <div class="card-spacer mt-n25">
        <div class="row m-0">
          <div class="col">
            <NumberWidget
              title="Créditos Certificados"
              :total="totals.credits"
              color="rgb(223, 232, 240)"
              text-color="#797979"
              route="/ordens/nova/certificado"
              :loading="isLoading"
            />
          </div>
          <div class="col">
            <NumberWidget
              :month="month"
              :year="year"
              :type="1"
              title="Certificados Emitidos"
              :total="totals.emmited_manual"
              color="rgb(223, 232, 240)"
              text-color="#797979"
              route="/certificates"
              :loading="isLoading"
            />
          </div>
        </div>
        <div class="text-center mt-7 mb-6" v-if="totals.total_orders > 0">
          <h3>Certificados Integração</h3>
        </div>
        <div class="row m-0" v-if="totals.total_orders > 0">
          <div class="col">
            <NumberWidget
              :color="color"
              title="Certificados a emitir"
              text-color="#ffffff"
              :route="`/ordens?month=${month}&year=${year}`"
              :total="totals.total_orders"
              :loading="isLoading"
            />
          </div>
          <div class="col">
            <NumberWidget
              :month="month"
              :year="year"
              :type="2"
              :color="color"
              :total="totals.emmited_integrated"
              title="Certificados Emitidos"
              text-color="#ffffff"
              route="/certificates"
              :loading="isLoading"
            />
          </div>
        </div>
        <div class="row m-0">
          <div
            class="col bg-light-danger px-6 py-8 rounded-xl mt-7 text-center"
            :style="`background-color:${color}!important;color:#ffffff;font-size: 1.8em;font-weight: bold;`"
            @click="$router.push('/scanner')"
          >
            Validar Certificado
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DashboardNumbers',

  data: () => ({
    isLoading: false,
    totals: {
      credits: null,
      emmited_integrated: null,
      emmited_manual: null,
      total_orders: null
    },
    email: null,
    month: null,
    year: null
  }),

  computed: {
    ...mapGetters(['store']),
    ...mapGetters('orders', {
      orders: 'GET_ORDERS',
      loadingOrders: 'GET_LOADER',
      totalOrders: 'GET_ORDERS_TOTAL'
    }),
    ...mapGetters('certificates', {
      loadingCertificates: 'GET_LOADER'
    }),

    params() {
      return { month: this.month, year: this.year }
    },

    store() {
      return this.$store.state.auth.store
    },

    user() {
      return this.$store.state.auth.user
    },

    orders() {
      let { orders } = this.$store.state.orders
      if (!orders) {
        return []
      }
      return orders
    },

    certificates() {
      let { certificates } = this.$store.state.certificates
      if (!certificates) {
        return []
      }
      return certificates
    },

    manualCertificates() {
      if (this.certificates.length == 0) {
        return 0
      }
      return this.certificates.filter((cert) => cert.type == 1).length
    },

    integrationCertificates() {
      if (this.certificates.length == 0) {
        return 0
      }

      return this.certificates.filter((cert) => cert.type == 2).length
    },

    color() {
      return this.store.color
    },

    credits() {
      return this.store.credits
    },

    currentMonth() {
      let date = new Date()
      return date.getMonth() + 1
    },

    currentYear() {
      let date = new Date()
      return date.getFullYear()
    }
  },

  methods: {
    ...mapActions('orders', ['fetchOrders']),
    ...mapActions('certificates', ['fetchCertificates']),
    ...mapActions('menus', ['fetchType', 'fetchTechnology']),

    getTotals(params) {
      this.isLoading = true
      this.$http
        .query(`/totals`, {
          params: {
            month: params.month,
            year: params.year
          }
        })
        .then((res) => (this.totals = res.data))
        .finally(() => {
          this.isLoading = false
          localStorage.removeItem('firstLogin')
        })
    },

    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },

    setMonth(month) {
      this.month = month
      if (this.params.year) {
        this.getTotals({ month: this.month, year: this.year })
      }
    },

    setYear(year) {
      this.year = year
      if (this.params.year) {
        this.getTotals({ month: this.month, year: this.year })
      }
    },

    checkConfirmed() {
      if (this.store.cofirmed == 0) {
        setTimeout(() => {
          this.$bvModal.show('modal-1')
        }, 500)
        setTimeout(() => {
          localStorage.clear()
          this.$router.push('/login')
        }, 5000)
      }
    },

    resendConfirmation() {
      this.$http.post('/resend').then(({ data }) => alert(data.msg))
    }
  },

  async mounted() {
    if (this.orders.length === 0) {
      const date = new Date()
      const year = await date.getFullYear()
      this.month = this.currentMonth
      this.year = year
      if (year) {
        if (localStorage.getItem('firstLogin')) {
          this.fetchOrders({ month: this.currentMonth, year: year })
          this.fetchCertificates({
            month: this.currentMonth,
            year: date.getFullYear()
          })
          if (!this.loadingCertificates && !this.loadingOrders) {
            this.getTotals({ month: this.currentMonth, year: year })
          }
        } else {
          this.getTotals({ month: this.currentMonth, year: year })
        }
      }
    }
    this.checkConfirmed()
  }
}
</script>

<style lang="css">
.h94 {
  height: 94px;
}
.card-spacer {
  padding: 2rem 1.25rem !important;
}
.backBottom {
  background: rgb(223, 232, 240);
  height: 120px;
  position: absolute;
  bottom: 200px;
  width: 100%;
  z-index: 0;
}
</style>
