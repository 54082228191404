import ApiService from '../../api.service'

export function fetchCertificates({ commit }, payload) {
  commit('SET_LOADER', true)
  let endpoint = '/certificate'

  if ((payload && payload.month !== undefined) || payload.year !== undefined) {
    endpoint = `/certificate?month=${payload.month}&year=${payload.year}`
  }

  if ((payload && payload.month !== undefined) || payload.type !== undefined) {
    endpoint = `/certificate?month=${payload.month}&year=${payload.year}&type=${payload.type}`
  }

  if (payload.search) {
    endpoint = `/certificate/?search=${encodeURI(payload.search)}`
  }

  return new Promise((resolve) => {
    ApiService.query(endpoint)
      .then((res) => {
        commit('SET_CERTIFICATES', res)
        resolve(res)
      })
      .catch(() => {
        commit('SET_CERTIFICATES', [])
      })
      .finally(() => commit('SET_LOADER', false))
  })
}
