<template>
  <div v-if="!type">
    <q-card
      v-for="(type, key) in types.flat()"
      :key="key"
      @click.prevent.native="setValue(type)"
    >
      <q-card-section>
        <div class="text-h5">{{ type.label.toUpperCase() }}</div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductTypeManual',

  computed: {
    ...mapGetters('menus', {
      types: 'getTypes',
      type: 'getType'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel']),
    ...mapActions('orders', ['setType']),

    async setValue(val) {
      this.setType(val.value)
      this.addPanel({
        ...val,
        action: 'removeType'
      })
    }
  }
}
</script>
