var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":_vm.id}},[(_vm.openCalculator)?_c('OptCalculator',{on:{"input-change":_vm.setValue,"close":_vm.goToNext}}):_vm._e(),_c('q-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCard),expression:"showCard"}],class:{ 'my-card': _vm.openCalculator },attrs:{"id":_vm.next},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_c('q-card-section',{staticClass:"q-pb-sm"},[_c('div',{},[_vm._v(_vm._s(_vm.title))])]),_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{class:_vm.textClass},[_vm._v(_vm._s(_vm.value))])])],1),(
      _vm.id === 'horizontalPrismAxisLeft' || _vm.id === 'horizontalPrismAxisRight'
    )?_c('div',{staticClass:"radioGroup"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-2","options":[
          {
            text: 'NASAL',
            value: 'NASAL'
          },
          {
            text: 'TEMPORAL',
            value: 'TEMPORAL'
          }
        ],"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","size":"sm","name":"radio-btn-outline","buttons":""},model:{value:(_vm.horizontalAxis),callback:function ($$v) {_vm.horizontalAxis=$$v},expression:"horizontalAxis"}})]}}],null,false,1739062895)})],1):_vm._e(),(_vm.id === 'verticalPrismAxisLeft' || _vm.id === 'verticalPrismAxisRight')?_c('div',{staticClass:"radioGroup"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-2","options":[
          {
            text: 'SUPERIOR',
            value: 'UP'
          },
          {
            text: 'INFERIOR',
            value: 'DOWN'
          }
        ],"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","size":"sm","name":"radio-btn-outline","buttons":""},model:{value:(_vm.verticalAxis),callback:function ($$v) {_vm.verticalAxis=$$v},expression:"verticalAxis"}})]}}],null,false,886300099)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }