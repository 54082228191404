<template>
  <div>
    <div
      v-if="isSaving"
      style="
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      "
    >
      <div>
        <Loading />
      </div>
    </div>
    <div v-else>
      <h6>Fabricante:</h6>
      <ManufacturerList
        @input="setManufacurer"
        :manufacturer="form.manufacturer_id"
        v-if="!loading"
      />
      <h6>Imagem:</h6>
      <div class="row mb-10" v-if="!img">
        <div class="col">
          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-success="setImage"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                Arraste e solte sua imagem aqui!
              </h3>
              <div class="subtitle">
                ...ou clique para selecionar uma imagem do seu computador.
              </div>
            </div>
          </vue-dropzone>
          <small>Imagens com no máximo 5mb de tamanho e 1024px x 768px</small>
        </div>
      </div>

      <div class="row mb-10" v-if="img">
        <div class="col">
          <cropper
            ref="cropper"
            class="twitter-cropper"
            background-class="twitter-cropper__background"
            foreground-class="twitter-cropper__foreground"
            image-restriction="stencil"
            default-boundaries="fill"
            :stencil-size="{
              width: 640,
              height: 480
            }"
            :stencil-props="{
              lines: {},
              handlers: {},
              movable: false,
              scalable: false,
              previewClass: 'twitter-cropper__stencil'
            }"
            :transitions="false"
            :debounce="false"
            :src="img"
            @change="onChange"
          />

          <!-- <navigation :zoom="form.zoom" @change="onZoom" /> -->
          <b-button
            pill
            variant="danger"
            style="
              position: absolute;
              right: 23px;
              margin-top: -100px;
              border-radius: 30px !important;
              padding: 4px 6px;
              text-align: center;
              padding-left: 10px;
            "
            @click="download"
          >
            <i
              class="menu-icon flaticon2-cross"
              style="font-size: 12px; margin-top: -2px"
            ></i>
          </b-button>
        </div>
      </div>

      <h6 class="mb-8 mt-20">Cor:</h6>
      <div class="row mb-10">
        <div class="col" style="display: flex; align-items: center">
          <ColorPicker
            :width="300"
            :height="300"
            :disabled="false"
            :startColor="form.color"
            @colorChange="onColorChange"
            style="margin: 0 auto"
          ></ColorPicker>
        </div>
        <div class="col">
          <CertificatePreview :color="form.color" :image="image" />
        </div>
      </div>
      <h6 class="mb-8 mt-20">Descrição:</h6>
      <div class="row mb-10">
        <div class="col">
          <b-form-group id="input-group-1" label="Título:" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="form.title"
              placeholder="Nome da linha de produtos"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            id="input-group-1"
            label="Garantia:"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="form.warranty"
              placeholder="Digitar total em meses"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            id="input-group-2"
            label="Linha de produtos:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              type="text"
              required
              v-model="form.number"
              placeholder="Digite aqui o ID da linha de produtos"
            ></b-form-input>
            <small>
              Para vincular a imagem do certificado para mais de uma linha de
              produtos, separar os códigos por ponto e virgula, exemplo: 123;
              456; 789
            </small>
          </b-form-group>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12" style="display: flex">
          <div class="text-left" style="width: 50%">
            <b-button variant="danger" @click="remove">DELETAR</b-button>
          </div>
          <div class="text-right" style="width: 50%">
            <b-button variant="right" @click="$router.back()"
              >CANCELAR</b-button
            >
            <b-button variant="success" @click="save">SALVAR</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ColorPicker from 'vue-color-picker-wheel'

export default {
  components: {
    Cropper,
    ColorPicker,
    vueDropzone: vue2Dropzone
  },
  data: () => ({
    message: 'salvando...',
    isSaving: false,
    loading: false,
    file: [],
    cropper: null,
    category: null,
    image: null,
    form: {
      manufacturer_id: null,
      image: null,
      coordinates: null,
      warranty: null,
      zoom: 0,
      title: null,
      number: null,
      color: '#CDC387'
    },
    img: false,
    dropzoneOptions: {
      url: 'https://httpbin.org/post',
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { 'My-Awesome-Header': 'header value' }
    }
  }),

  methods: {
    remove() {
      if (
        window.confirm(
          'Deseka mesmo remover este produto? Esta ação não poderá ser desfeita.'
        )
      ) {
        this.isSaving = true
        this.$http
          .delete(`/categories/${this.$route.params.id}`)
          .then(() => {
            alert('Produto removido com sucesso!')
            this.$router.push('/categories')
          })
          .finally(() => (this.isSaving = false))
      }
    },

    async fetchCategory() {
      this.isSaving = true
      this.message = ''
      const { data } = await this.$http.get(
        `/categories/${this.$route.params.id}`
      )
      this.form = { ...data }
      this.setManufacurer(data.manufacturer.id)
      this.image = this.form.image
      this.img = this.form.data_url.encoded
      this.isSaving = false
      this.message = 'salvando...'
    },

    setImage(file) {
      this.img = file.dataURL
    },

    onColorChange(color) {
      this.form.color = color
    },

    setManufacurer(val) {
      this.form.manufacturer_id = val
    },

    download() {
      this.img = false
      this.image = ''
      this.form.image = null
    },

    onChange() {
      const cropper = this.$refs.cropper
      this.image = cropper.getResult().canvas.toDataURL()
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          this.form.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight)
        } else {
          this.form.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth)
        }
      }
    },

    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(',')[1])
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      let ab = new ArrayBuffer(byteString.length)
      let ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      let blob = new Blob([ab], { type: mimeString })
      return blob
    },

    onZoom(value) {
      const cropper = this.$refs.cropper
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight
          cropper.zoom(
            ((1 - this.form.zoom) * cropper.imageSize.height + minHeight) /
              ((1 - value) * cropper.imageSize.height + minHeight)
          )
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth
          cropper.zoom(
            ((1 - this.form.zoom) * cropper.imageSize.width + minWidth) /
              ((1 - value) * cropper.imageSize.width + minWidth)
          )
        }
      }
    },

    async save() {
      this.isSaving = true
      const cropper = this.$refs.cropper
      let data = new FormData()
      data.append('manufacturer_id', this.form.manufacturer_id)
      data.append('number', this.form.number)
      data.append('warranty', this.form.warranty)
      data.append('color', this.form.color)
      if (this.form.title) {
        data.append('title', this.form.title)
      }
      let blob = await this.dataURItoBlob(
        cropper.getResult().canvas.toDataURL()
      )

      data.append('image', blob)

      let config = {
        header: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('id_token')}`
        }
      }
      if (this.$route.params.id) {
        await this.$http
          .put(`/categories/${this.$route.params.id}`, {
            ...this.form,
            image: this.image
          })
          .finally(() => (this.isSaving = false))
        alert('Produto salvo com sucesso!')
        this.$router.push('/categories')
      } else {
        await this.$http
          .post('/categories', data, config)
          .finally(() => (this.isSaving = false))
        alert('Produto salvo com sucesso!')
        this.$router.push('/categories')
      }
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.fetchCategory()
    }
  }
}
</script>
<style lang="css">
.vue-dropzone {
  background: #f5f5f5;
  box-shadow: 1px 1px 5px #cccccc73 inset;
  border: 1px solid #f5f5f5;
}
.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #000;
  font-size: 12px !important;
}

.subtitle {
  color: #314b5f;
}
</style>
