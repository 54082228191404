<template>
  <div>
    <button class="float-right mt-5 botao" @click.prevent.stop="openConfirm">
      <i class="menu-icon flaticon2-edit"></i>
    </button>
    <b-modal ref="customer-modal" @ok="save" @cancel="closeModal()">
      <div v-if="loading" class="text-center">
        <div
          class="spinner spinner-primary mx-auto"
          style="width: 40px; margin: 0 auto"
        ></div>
      </div>
      <div class="my-4">
        <input
          class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Nome"
          v-model="customer.name"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        />
        <input
          class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Digite o email"
          v-model="customer.email"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        />
        <input
          class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="number"
          placeholder="Celular, com ddd, ex. 11 987654321"
          v-model="customer.phone"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          maxlength="11"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
        <!-- <input
          class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="CPF"
          v-mask="'###.###.###-##'"
          v-model="customer.document"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
        /> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'OrderCustomerEdit',

  props: ['name', 'email', 'document', 'phone', 'orderId', 'idx'],

  data: () => ({
    loading: false,
    customer: {
      name: null,
      email: null,
      document: null,
      phone: null
    }
  }),

  methods: {
    openConfirm() {
      this.$refs['customer-modal'].show()
    },

    save() {
      this.loading = true
      let payload = { ...this.customer }
      payload.order_id = this.orderId
      this.$http
        .post('customers', payload)
        .then(({ data }) => {
          this.customer = data
          this.$emit('save', { customer: data, idx: this.idx })
          this.loading = false
          this.closeModal()
        })
        .catch(() => {
          this.loading = false
          this.$refs['customer-modal'].show()
        })
    },

    closeModal() {
      this.$refs['customer-modal'].hide()
    }
  },

  mounted() {
    if (this.name) {
      this.customer.name = this.name
    }
    if (this.email) {
      this.customer.email = this.email
    }
    if (this.document) {
      this.customer.document = this.document
    }
    if (this.phone) {
      this.customer.phone = this.phone
    }
  }
}
</script>

<style lang="scss" scoped>
.botao {
  border: 1px solid #fff;
  background: #fff;
  margin-right: 20px;
  right: 0;
  position: absolute;
  padding: 0;
}
</style>
