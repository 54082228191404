<template>
  <div>
    <div style="margin-top: 20px"><h2>Paciente:</h2></div>
    <q-card>
      <q-card-section>
        <div class="q-gutter-md">
          <input
            class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
            type="text"
            placeholder="Nome"
            v-model="customer.name"
            autocomplete="off"
            style="width: 100%; font-size: 1.4em !important; font-weight: bold"
            @change="dispatchCustomer"
          />
          <input
            class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
            type="text"
            placeholder="Email"
            v-model="customer.email"
            autocomplete="off"
            style="width: 100%; font-size: 1.4em !important; font-weight: bold"
            @change="dispatchCustomer"
          />
          <input
            class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
            type="number"
            placeholder="Celular, com ddd, ex. 11 987654321"
            v-model="customer.phone"
            autocomplete="off"
            style="width: 100%; font-size: 1.4em !important; font-weight: bold"
            @change="dispatchCustomer"
            maxlength="11"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductCustomer',

  props: ['person'],

  data: () => ({
    customer: {
      email: null,
      name: null,
      phone: null,
      type: 'SALESMAN'
    },
    dominantEye: null
  }),

  watch: {
    customer: {
      handler(customer) {
        this.$emit('input', customer)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('orders', { order: 'getNewOrder' })
  },

  methods: {
    ...mapActions('orders', ['setDominantEye', 'setCustomer']),

    dispatchCustomer() {
      const val = JSON.parse(JSON.stringify(this.customer))
      this.setCustomer(val)
    }
  },

  mounted() {
    if (this.person) {
      this.customer = this.person
    }
  }
}
</script>
