const keyboards = {
  full: [
    [
      { value: '+/-', color: '#EDEDED' },
      { value: '11-20', color: '#EDEDED' },
      { value: 'back', color: '#EDEDED' }
    ],
    [
      { value: 1, color: '#FFFFFF' },
      { value: 2, color: '#FFFFFF' },
      { value: 0, color: '#FFFFFF' }
    ],
    [
      { value: 3, color: '#FFFFFF' },
      { value: 4, color: '#FFFFFF' },
      { value: 0.25, color: '#FFFFFF' }
    ],
    [
      { value: 5, color: '#FFFFFF' },
      { value: 6, color: '#FFFFFF' },
      { value: 0.5, color: '#FFFFFF' }
    ],
    [
      { value: 7, color: '#FFFFFF' },
      { value: 8, color: '#FFFFFF' },
      { value: 0.75, color: '#FFFFFF' }
    ],
    [
      { value: 9, color: '#FFFFFF' },
      { value: 10, color: '#FFFFFF' },
      { value: 'OK', color: '#EDEDED' }
    ],
    [
      { value: 0.25, color: '#FFFFFF' },
      { value: 0.5, color: '#FFFFFF' },
      { value: 0.75, color: '#FFFFFF' }
    ]
  ],
  simple: [
    [
      { value: '+/-', color: '#EDEDED' },
      { value: '0-10', color: '#EDEDED' },
      { value: 'back', color: '#EDEDED' }
    ],
    [
      { value: 11, color: '#FFFFFF' },
      { value: 12, color: '#FFFFFF' },
      { value: 0, color: '#FFFFFF' }
    ],
    [
      { value: 13, color: '#FFFFFF' },
      { value: 14, color: '#FFFFFF' },
      { value: 0.25, color: '#FFFFFF' }
    ],
    [
      { value: 15, color: '#FFFFFF' },
      { value: 16, color: '#FFFFFF' },
      { value: 0.5, color: '#FFFFFF' }
    ],
    [
      { value: 17, color: '#FFFFFF' },
      { value: 18, color: '#FFFFFF' },
      { value: 0.75, color: '#FFFFFF' }
    ],
    [
      { value: 19, color: '#FFFFFF' },
      { value: 20, color: '#FFFFFF' },
      { value: 'OK', color: '#EDEDED' }
    ],
    [
      { value: 0.25, color: '#FFFFFF' },
      { value: 0.5, color: '#FFFFFF' },
      { value: 0.75, color: '#FFFFFF' }
    ]
  ],
  minimal: [
    [
      { value: '', color: '#EDEDED' },
      { value: '', color: '#EDEDED' },
      { value: 'back', color: '#EDEDED' }
    ],
    [
      { value: 1, color: '#FFFFFF' },
      { value: 2, color: '#FFFFFF' },
      { value: 3, color: '#FFFFFF' }
    ],
    [
      { value: 4, color: '#FFFFFF' },
      { value: 5, color: '#FFFFFF' },
      { value: 6, color: '#FFFFFF' }
    ],
    [
      { value: 7, color: '#FFFFFF' },
      { value: 8, color: '#FFFFFF' },
      { value: 9, color: '#FFFFFF' }
    ],
    [
      { value: '.25', color: '#FFFFFF' },
      { value: '.50', color: '#FFFFFF' },
      { value: '.75', color: '#FFFFFF' }
    ],
    [
      { value: '+/-', color: '#EDEDED' },
      { value: 0, color: '#FFFFFF' },
      { value: 'OK', color: '#EDEDED' }
    ]
  ]
}

export default keyboards
